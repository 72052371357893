import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import all your components
import Navbar from './components/Navbar';
import ServiceSlider from './components/ServiceSlider';
import About from './components/About';
import Services from './components/Services';
import CallToAction from './components/CallToAction';
import Portfolio from './components/Portfolio';
import Team from './components/TeamMembers';
import Testimonials from './components/Testimonials';
import Clients from './components/Clients';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Career from './components/Carrer';
import WebDevelopmentPage from './pages/webDevelopment';
import AppDevelopmentPage from './pages/appDevelopment';
import LogoDesign from './pages/logoDesign'
import UiUxDevelopmentPage from './pages/uiuxDevelopment';
import SeoDevelopmentPage from './pages/seoDevelopment';
import DigitalMarketingDevelopmentPage from './pages/digitalMarketingDevelopment';
import GraphicDevelopmentPage from './pages/graphicDesignDevelopment';
import WebHostingPage from './pages/webHostingDevelopment';
import TypesOfWebsites from './components/TypesOfWebsite';
import ScrollToTop from './components/ScrollToTop';
import GetQuote from './pages/getAquote';


const Home = () => (
  <>
    <ServiceSlider />
    <Services />
    <About />
    {/* <AmdocsPage/> */}
    <TypesOfWebsites/>
    <CallToAction />
    <Portfolio />
    {/* <Team /> */}
    {/* <Testimonials /> */}
    {/* <Clients /> */}
    {/* <ContactUs /> */}
  </>
);

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Navbar />

        {/* Define Routes */}
        <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/career" element={<Career/>} />
          <Route path="/web-development" element = {<WebDevelopmentPage/>} />
          <Route path="/app-development" element = {<AppDevelopmentPage/>} />
          <Route path="/logo-designing" element = {<LogoDesign/>} />
          <Route path="/ui-ux-design" element = {<UiUxDevelopmentPage/>} />
          <Route path="/seo" element = {<SeoDevelopmentPage/>} />
          <Route path="/digital-marketing" element = {<DigitalMarketingDevelopmentPage/>} />
          <Route path="/graphic-design" element = {<GraphicDevelopmentPage/>} />
          <Route path="/web-hosting" element = {<WebHostingPage/>} />
          <Route path="/get-a-quate" element = {<GetQuote/>} />
        </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;